export class NavigationUtils {

    static goToHome() {
        document.location.href = '/';
    }

    static goToRegister() {
        document.location.href = '/pages/register/';
    }

    static goToSignin() {
        document.location.href = '/pages/signin/';
    }

    static goToMessage(id: string, args?: { [key: string]: string }) {
        let argsString = '';

        if (args) {
            for (const [key, value] of Object.entries(args)) {
                argsString += `&_${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        }
        document.location.href = `/pages/message/?id=${encodeURIComponent(id)}${argsString}`;
    }

    static goToDashboard() {
        document.location.href = '/pages/dashboard/';
    }

    static goToAdminEnter() {
        document.location.href = '/pages/admin_enter/';
    }

    static goToAdmin() {
        document.location.href = '/pages/admin/';
    }
}
