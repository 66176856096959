export default {
    // ==========================================================================================
    // Common

    COMMON: {
        EMAIL: {
            EN: 'E-mail',
            JA: 'メール',
        },
        PASSWORD: {
            EN: 'Password',
            JA: 'パスワード',
        },
        BACK: {
            EN: 'Back',
            JA: '戻る',
        },
        SIGNIN: {
            EN: 'Sign-in',
            JA: 'サインイン',
        },
        REGISTER: {
            EN: 'Register',
            JA: '登録',
        },
        FORGOT_PASSWORD: {
            EN: 'Forgot password',
            JA: 'Forgot password',
        },
        SEND_PASSWORD_RESET_EMAIL: {
            EN: 'Send password reset email',
            JA: 'Send password reset email',
        },
        BACK_TO_HOME_PAGE: {
            EN: 'Back to home page',
            JA: 'Back to home page',
        },
        BILLING: {
            EN: 'Billing',
            JA: 'Billing',
        },
        ACTIVATE_BILLING: {
            EN: 'Activate billing',
            JA: 'Activate billing',
        },
        BACK_TO_USER_DASHBOARD: {
            EN: 'Back to user dashboard',
            JA: 'Back to user dashboard',
        },
        ADMIN: {
            EN: 'Admin',
            JA: '管理',
        },
        LOGOUT: {
            EN: 'Logout',
            JA: 'Logout',
        },
        REMAINING_FREE_INFERENCE_COUNT: {
            EN: 'Remaining free inferences:',
            JA: '<Remaining free inferences>:',
        },
        CREATE_TOKEN: {
            EN: 'Create',
            JA: 'Create',
        },
        REFRESH_LIST: {
            EN: 'Refresh list',
            JA: 'Refresh list',
        },
        DAYS: {
            EN: '{n} days',
            JA: '{n}日間',
        },
        NO_TOKENS: {
            EN: 'You do not have any tokens... for the moment.',
            JA: 'You do not have any tokens... for the moment.',
        },
        DELETE: {
            EN: 'Delete',
            JA: 'Delete',
        },
        API_TOKEN: {
            EN: 'API Token',
            JA: 'API Token',
        },
        VALIDITY_DATE: { // Context: Used in in tokens table header to describe the column.
            EN: 'Validity date',
            JA: 'Validity date',
        },
        TOKEN_VALIDITY_PERIOD: { // Context: Used in token creation label to describe the dropdown.
            EN: 'Validity period',
            JA: 'Validity period',
        },
        DESCRIPTION: { // Context: Used in token creation label to describe the textbox, and in tokens table header to describe the column.
            EN: 'Description',
            JA: 'Description',
        },
        REFRESH: {
            EN: 'Refresh',
            JA: 'Refresh',
        },
        START_DATE: {
            EN: 'Start date',
            JA: 'Start date',
        },
        END_DATE: {
            EN: 'End date',
            JA: 'End date',
        },
        COPY_TO_CLIPBOARD: {
            EN: 'Copy to clipboard',
            JA: 'Copy to clipboard',
        },
        PAID: {
            EN: 'Paid',
            JA: '<Paid>',
        },
        FREE: {
            EN: 'Free',
            JA: '<Free>',
        },
        GRAND_TOTAL: {
            EN: 'Grand total',
            JA: '<Grand total>',
        },
        TOTAL: {
            EN: 'Total',
            JA: '<Total>',
        },
        DETAILS: {
            EN: 'Details',
            JA: '<Details>',
        },
        COUNT: {
            EN: 'Count',
            JA: '<Count>',
        },
        DURATION: { // Context: How long time an action took to run.
            EN: 'Duration',
            JA: '<Duration>',
        },
        MILLISECONDS: {
            EN: "{n} ms",
            JA: "{n} ミリ秒",
        },
        SECONDS: {
            EN: "{n} sec",
            JA: "{n} 秒",
        },
        MINUTES: {
            EN: "{n} min",
            JA: "{n} 分",
        },
        HOURS: {
            EN: "{n} h",
            JA: "{n}時",
        },
        KEY_VALUE_SEPARATOR: { // Context: When a value is printed beside its label, such as "Age: 25", "Age" is the key, "25" is the value, ": " is the separator.
            EN: ": ",
            JA: ": ",
        },
        INFERENCE_TYPE_HEADER: {
            EN: "Inference type",
            JA: "Inference type",
        },
        INFERENCE_MODEL_NAME_HEADER: {
            EN: "Model name",
            JA: "Model name",
        },
        INFERENCE_PER_STATUS_VALUES_HEADER: {
            EN: "Per status values",
            JA: "Per status values",
        },
        INFERENCE_PER_MODEL_VALUES_HEADER: {
            EN: "Per model values",
            JA: "Per model values",
        },
        INFERENCE_PER_TYPE_VALUES_HEADER: {
            EN: "Per type values",
            JA: "Per type values",
        },
        UPDATE_USER_ADMIN: {
            EN: 'Change user\'s admin status',
            JA: 'Change user\'s admin status',
        },
        SET_AS_ADMIN: {
            EN: 'Set as admin',
            JA: 'Set as admin',
        },
        SET_AS_USER: {
            EN: 'Set as user',
            JA: 'Set as user',
        },
        QUERY_USER_ADMIN_STATUS: {
            EN: 'Query',
            JA: 'Query',
        },
        CURRENT_STATUS: {
            EN: 'Current status:',
            JA: 'Current status:',
        },
        CHECKING: {
            EN: 'Checking...',
            JA: 'Checking...',
        },
        UPDATING: {
            EN: 'Updating...',
            JA: 'Updating...',
        },
        VERSION: {
            EN: 'Version: {version}',
            JA: 'Version: {version}',
        },
        VERSIONS: { // Context: Title of the 'Versions' section of the admin page.
            EN: 'Versions',
            JA: 'Versions',
        },
        AUTH_USER_INFO: {
            EN: 'Auth user info',
            JA: 'Auth user info',
        },
        QUERY_AUTH_USER_INFO: {
            EN: 'Query',
            JA: 'Query',
        },
        INTERNAL_KPIS: {
            EN: 'Internal KPIs',
            JA: 'Internal KPIs',
        },
        COMMIT: {
            EN: 'Commit',
            JA: 'Commit',
        },
        BRANCH: {
            EN: 'Branch',
            JA: 'Branch',
        },
        TAGS: {
            EN: 'Tags',
            JA: 'Tags',
        },
        CLEAR: {
            EN: 'Clear',
            JA: 'Clear',
        },
        ERROR: {
            EN: '<ERROR>',
            JA: '<エラー>',
        },
        CREATE_B2B_ORGANISATION: {
            EN: 'Create B2B organisation',
            JA: 'Create B2B organisation',
        },
        LIST_B2B_ORGANISATIONS: {
            EN: 'List B2B organisations',
            JA: 'List B2B organisations',
        },
        RENEW_B2B_ORGANISATION: {
            EN: 'Renew B2B organisation',
            JA: 'Renew B2B organisation',
        },
        NAME: {
            EN: 'Name',
            JA: 'Name',
        },
        CREATION_DATE: {
            EN: 'Creation date',
            JA: 'Creation date',
        },
        VALID_UNTIL: {
            EN: 'Valid until',
            JA: 'Valid until',
        },
        SEARCH: {
            EN: 'Search',
            JA: 'Search',
        },
        ID: {
            EN: 'ID',
            JA: 'ID',
        },
        BILLING_SERVICE_STATUS: {
            EN: 'Billing service status',
            JA: 'Billing service status',
        },
        ACTIVE: {
            EN: 'Active',
            JA: 'Active',
        },
        INACTIVE: {
            EN: 'Inactive',
            JA: 'Inactive',
        },
        LIST_ORG_TOKENS: {
            EN: 'List organisation tokens',
            JA: 'List organisation tokens',
        },
        NO_RESULT: {
            EN: 'No results',
            JA: 'No results',
        },
    },

    MENUS: {
        TOKENS: {
            EN: 'Tokens',
            JA: '<Tokens>',
        },
        INFERENCES: {
            EN: 'Inferences',
            JA: '<Inferences>',
        },
    },

    MESSAGES: {
        PASSWORD_RESET_EMAIL_SENT_OK: {
            EN: 'Password reset email successfully sent to {email}.',
            JA: 'Password reset email successfully sent to {email}.',
        },
        REGISTRATION_DONE: {
            EN: 'Registration done. You will soon receive a confirmation email at {email}.',
            JA: 'Registration done. You will soon receive a confirmation email at {email}.',
        },
        ACTIVATE_BILLING: {
            EN: 'This action will trigger several resources creation. Note that it is not needed as long as you still have remaining free inferences. However, feel free to activate the billing any time. Type \'yes\' to agree.',
            JA: 'This action will trigger several resources creation. Note that it is not needed as long as you still have remaining free inferences. However, feel free to activate the billing any time. Type \'yes\' to agree.',
        },
        START_DATE_NOT_SET: {
            EN: 'Please set start date.',
            JA: 'Please set start date.',
        },
        END_DATE_NOT_SET: {
            EN: 'Please set end date.',
            JA: 'Please set end date.',
        },
        CHECKING_STATUS: {
            EN: 'Checking status...',
            JA: 'Checking status...',
        },
        DELETE_TOKEN_CONFIRMATION: {
            EN: 'You are about to delete token {id} ({description}). Are you sure ? Type \'yes\' to validate.',
            JA: 'You are about to delete token {id} ({description}). Are you sure ? Type \'yes\' to validate.',
        },
        ORGANISATION_CREATED: {
            EN: 'Organisation created',
            JA: 'Organisation created',
        },
        ORGANISATION_RENEWED: {
            EN: 'Organisation renewed',
            JA: 'Organisation renewed',
        },
    },

    ERRORS: {
        FAILED_TO_RESET_PASSWORD: {
            EN: 'Failed to reset password. Status: {status}. Message: {message}',
            JA: 'Failed to reset password. Status: {status}. Message: {message}',
        },
        FAILED_TO_REGISTER: {
            EN: 'Failed to register. Status: {status}. Message: {message}',
            JA: 'Failed to register. Status: {status}. Message: {message}',
        },
        FAILED_TO_FETCH_USER_INFO: {
            EN: 'Failed to fetch user information. Status: {status}. Message: {message}',
            JA: 'Failed to fetch user information. Status: {status}. Message: {message}',
        },
        FAILED_TO_FETCH_TOKENS: {
            EN: 'Failed to fetch tokens. Status: {status}. Message: {message}',
            JA: 'Failed to fetch tokens. Status: {status}. Message: {message}',
        },
        FAILED_TO_FETCH_INFERENCES: {
            EN: 'Failed to fetch inferences. Status: {status}. Message: {message}',
            JA: 'Failed to fetch inferences. Status: {status}. Message: {message}',
        },
        FAILED_TO_CREATE_TOKEN: {
            EN: 'Failed to create token. Status: {status}. Message: {message}',
            JA: 'Failed to create token. Status: {status}. Message: {message}',
        },
        FAILED_TO_DELETE_TOKEN: {
            EN: 'Failed to delete token. Status: {status}. Message: {message}',
            JA: 'Failed to delete token. Status: {status}. Message: {message}',
        },
        NETWORK_ERROR: {
            EN: "A network error occurred: {err}",
            JA: "A network error occurred: {err}",
        },
        UNEXPECTED_ERROR: {
            EN: "An unexpected error occurred. A network error occurred: {err}",
            JA: "An unexpected error occurred. A network error occurred: {err}",
        },
        EMAIL_ADDRESS_EMPTY: {
            EN: "Email address must not be empty.",
            JA: "Email address must not be empty.",
        },
        PASSWORD_EMPTY: {
            EN: "Password must not be empty.",
            JA: "Password must not be empty.",
        },
        INVALID_EMAIL: {
            EN: 'Invalid email address.',
            JA: 'Invalid email address.',
        },
        USER_NOT_FOUND: {
            EN: 'User not found.',
            JA: 'User not found.',
        },
        INVALID_PASSWORD: {
            EN: 'Invalid password.',
            JA: 'Invalid password.',
        },
        FAILED_TO_ACTIVATE_BILLING: {
            EN: 'Failed to activate billing. Status: {status}. Message: {message}',
            JA: 'Failed to activate billing. Status: {status}. Message: {message}',
        },
        FAILED_TO_GET_BILLING_PORTAL_URL: {
            EN: 'Failed to obtain billing portal URL. Status: {status}. Message: {message}',
            JA: 'Failed to obtain billing portal URL. Status: {status}. Message: {message}',
        },
        FAILED_TO_UPDATE_BILLING_LANGUAGES: {
            EN: 'Failed to update billing languages. Status: {status}. Message: {message}',
            JA: 'Failed to update billing languages. Status: {status}. Message: {message}',
        },
        FAILED_TO_REQUEST_ADMIN_SESSION: {
            EN: 'Failed to request an administrator session. Message: {message}',
            JA: 'Failed to request an administrator session. Message: {message}',
        },
        FAILED_TO_FETCH_USER_ADMIN_STATUS: {
            EN: 'Failed to fetch user\'s admin status. Message: {message}',
            JA: 'Failed to fetch user\'s admin status. Message: {message}',
        },
        FAILED_TO_UPDATE_USER_ADMIN_STATUS: {
            EN: 'Failed to update user\'s admin status. Message: {message}',
            JA: 'Failed to update user\'s admin status. Message: {message}',
        },
        FAILED_TO_FETCH_VERSIONS: {
            EN: 'Failed to fetch versions. Message: {message}',
            JA: 'Failed to fetch versions. Message: {message}',
        },
        FAILED_TO_FETCH_INTERNAL_KPIS: {
            EN: 'Failed to fetch internal KPIs. Message: {message}',
            JA: 'Failed to fetch internal KPIs. Message: {message}',
        },
        FAILED_TO_FETCH_AUTH_USER_INFO: {
            EN: 'Failed to fetch auth user info. Message: {message}',
            JA: 'Failed to fetch auth user info. Message: {message}',
        },
        FAILED_TO_FETCH_ORG_TOKENS: {
            EN: 'Failed to fetch organisation tokens. Message: {message}',
            JA: 'Failed to fetch organisation tokens. Message: {message}',
        },
        ID_MISSING: {
            EN: 'ID is missing',
            JA: 'ID is missing',
        },
        NAME_MISSING: {
            EN: 'Name is missing',
            JA: 'Name is missing',
        },
        DESCRIPTION_MISSING: {
            EN: 'Description is missing',
            JA: 'Description is missing',
        },
        EMAIL_MISSING: {
            EN: 'Email is missing',
            JA: 'Email is missing',
        },
        VALID_UNTIL_DATE_INVALID: {
            EN: 'Valid until date is invalid',
            JA: 'Valid until date is invalid',
        },
        FAILED_TO_CREATE_B2B_ORGANISATION: {
            EN: 'Failed to create B2B organisation. Message: {message}',
            JA: 'Failed to create B2B organisation. Message: {message}',
        },
        FAILED_TO_LIST_B2B_ORGANISATIONS: {
            EN: 'Failed to list B2B organisations. Message: {message}',
            JA: 'Failed to list B2B organisations. Message: {message}',
        },
        FAILED_TO_RENEW_B2B_ORGANISATION: {
            EN: 'Failed to renew B2B organisation. Message: {message}',
            JA: 'Failed to renew B2B organisation. Message: {message}',
        },
        ERROR_OCCURED: { // This is only on the admin page.
            EN: 'An error occured, check the debug console for details',
            JA: 'An error occured, check the debug console for details',
        },
    },
}
