export interface ILanguageDataItem {
    [key: string]: string
}

export interface ILanguageData {
    [key: string]: string | ILanguageDataItem
}

export interface ISupportedLanguage {
    key: string
    text: string
}

/* Usage:
* One argument:
*     (id: string) Creates a new span element and sets the innerText property with translated "id".
* Two arguments:
*     (tagName: string, id: string) Creates a new "tagName" element and sets the innerText property with translated "id".
*     (element: HTMLElment, id: string) Sets the innerText property of the existing "element" with translated "id".
* Three arguments:
*     (tagName: string, propertyName: string, id: string) Creates a new "tagName" element and sets the "propertyName" property with translated "id".
*     (element: HTMLElment, propertyName: string, id: string) Sets the "propertyName" property of the existing "element" with translated "id".
*/

/*
Creates a new span element and sets the innerText property with translated "id".
*/
export interface IOneArgument {
    id: string
}

/*
Creates a new "tagName" element and sets the innerText property with translated "id".
*/
export interface ITwoArgumentsVariant1 {
    id: string
    tagName: string
}

/*
Sets the innerText property of the existing "element" with translated "id".
*/
export interface ITwoArgumentsVariant2 {
    id: string
    element: HTMLElement
}

/*
Creates a new "tagName" element and sets the "propertyName" property with translated "id".
*/
export interface IThreeArgumentsVariant1 {
    id: string
    propertyName: string
    tagName: string
}

/*
Sets the "propertyName" property of the existing "element" with translated "id".
*/
export interface IThreeArgumentsVariant2 {
    id: string
    propertyName: string
    element: HTMLElement
}

export class LocalizationManager {
    private _languageData: ILanguageData;
    private _supportedLanguages: ISupportedLanguage[];
    private _currentLanguageKey!: string;
    private _languageChangedHandlers!: Function[];

    constructor(languageData: ILanguageData) {
        this._languageData = languageData;

        this.reset();

        this._supportedLanguages = [
            { key: 'EN', text: 'English' },
            { key: 'JA', text: '日本語' }
        ];
    }

    getBrowserLanguageKey(): string {
        return navigator.language.split('-')[0].toUpperCase();
    }

    reset(): void {
        this._currentLanguageKey = 'EN';
        this._languageChangedHandlers = [];
    }

    getSupportedLanguages(): ISupportedLanguage[] {
        return this._supportedLanguages;
    }

    registerLanguageChange(handler: Function): Function {
        this._languageChangedHandlers.push(handler);

        return () => {
            this.unregisterLanguageChange(handler);
        };
    }

    unregisterLanguageChange(handler: Function): boolean {
        const index = this._languageChangedHandlers.indexOf(handler);

        if (index < 0) {
            return false;
        }

        this._languageChangedHandlers.splice(index, 1);

        return true;
    }

    getCurrentLanguageKey(): string {
        return this._currentLanguageKey;
    }

    setCurrentLanguageKey(languageKey: string): void {
        if (!languageKey) {
            languageKey = this.getBrowserLanguageKey();
        }

        if (this._currentLanguageKey === languageKey) {
            return;
        }

        this._currentLanguageKey = languageKey;

        for (let i = 0; i < this._languageChangedHandlers.length; i++) {
            const handler = this._languageChangedHandlers[i];
            if (handler()) {
                this._languageChangedHandlers.splice(i, 1);
                i--;
            }
        }
    }

    _findLanguagesObject(id: string): ILanguageDataItem | null {
        const ids = id.split('.');

        if (ids.length === 0) {
            return null;
        }

        let result: any = this._languageData;

        for (const _id of ids) {
            result = result[_id];
        }

        return result;
    }

    tryGetText(id: string): string | null {
        const langs = this._findLanguagesObject(id);

        if (!langs) {
            return null;
        }

        return langs[this._currentLanguageKey] || null;
    }

    getText(id: string): string {
        const langs = this._findLanguagesObject(id);

        if (!langs) {
            return `<${id}>`;
        }

        return langs[this._currentLanguageKey] || `<${langs.EN}>` || `<${id}>`;
    }

    /*
    Creates a new span element and sets the innerText property with translated "id".
    */
    _11(id: string): HTMLElement {
        return this._32(document.createElement('span'), 'innerText', id);
    }

    /*
    Creates a new "tagName" element and sets the innerText property with translated "id".
    */
    _21(tagName: string, id: string): HTMLElement {
        return this._32(document.createElement(tagName), 'innerText', id);
    }

    /*
    Sets the innerText property of the existing "element" with translated "id".
    */
    _22(element: HTMLElement, id: string): HTMLElement {
        return this._32(element, 'innerText', id);
    }

    /*
    Creates a new span element and sets the innerText property with interpreted (translation) "id" and interpretation values.
    */
    _23(id: string, keyValues: {[key: string]: string}): HTMLElement {
        return this._41(document.createElement('span'), 'innerText', id, keyValues);
    }

    /*
    Creates a new "tagName" element and sets the "propertyName" property with translated "id".
    */
    _31(tagName: string, propertyName: string, id: string): HTMLElement {
        return this._32(document.createElement(tagName), propertyName, id);
    }

    /*
    Sets the "propertyName" property of the existing "element" with translated "id".
    */
    _32(element: HTMLElement, propertyName: string, id: string): HTMLElement {
        (element as any)[propertyName] = this.getText(id);

        let elementWeakRef: WeakRef<HTMLElement>|null = new WeakRef(element);

        const onLanguageChanged = () => {
            if (elementWeakRef === null) {
                return;
            }

            const element = elementWeakRef.deref();

            if (!element) {
                elementWeakRef = null;
                return true; // true means "Yes please, remove me from registered handlers".
            }

            (element as any)[propertyName] = this.getText(id);

            return false;
        };

        this.registerLanguageChange(onLanguageChanged);

        return element;
    }

    /*
    Sets the "propertyName" property of the existing "element" with interpreted (translation) "id" and interpretation values.
    */
    _41(element: HTMLElement, propertyName: string, id: string, keyValues: {[key: string]: string}): HTMLElement {
        (element as any)[propertyName] = this.interpret(id, keyValues);

        let elementWeakRef: WeakRef<HTMLElement>|null = new WeakRef(element);

        const onLanguageChanged = () => {
            if (elementWeakRef === null) {
                return;
            }

            const element = elementWeakRef.deref();

            if (!element) {
                elementWeakRef = null;
                return true; // true means "Yes please, remove me from registered handlers".
            }

            (element as any)[propertyName] = this.interpret(id, keyValues);

            return false;
        };

        this.registerLanguageChange(onLanguageChanged);

        return element;
    }

    interpret(templateKey: string, keyValues: {[key: string]: string}): string {
        const template = this.getText(templateKey);

        const matches = template.matchAll(/(?<!\{)\{([A-Za-z_][A-Za-z_0-9]*)\}(?!\})/g);

        let index = 0;
        const resultParts: any[] = [];

        for (const match of matches) {
            if (match.length < 2) {
                console.error('Failed match (bad length):', match);
                continue;
            } else if (match.index === undefined) {
                console.error('Failed match (no index):', match);
                continue;
            }

            const key = match[1];

            if (keyValues[key] === undefined) {
                console.error('Failed match (bad key/value map): key:', key, 'keyValues:', keyValues);
                continue;
            }

            resultParts.push(template.slice(index, match.index));
            resultParts.push(keyValues[key]);

            index = match.index + match[0].length;
        }

        if (index <= template.length - 1) {
            resultParts.push(template.slice(index));
        }

        const result = resultParts.join('');

        return result;
    }
}
