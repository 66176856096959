import { RandomUtils } from './randomUtils';

const VISIBILITY_HIDDEN_CLASS_NAME = 'visibility-hidden';
const VISIBILITY_COLLAPSE_CLASS_NAME = 'visibility-collapse';

export class Menu {
    _entries: {
        menuElement: HTMLElement,
        contentElement: HTMLElement,
        onShow?: Function,
        onHide?: Function
    }[] = [];

    register(menuElement: HTMLElement, contentElement: HTMLElement, onShow?: Function, onHide?: Function): Menu {
        const index = this._entries.length;

        this._entries.push({menuElement, contentElement, onShow, onHide});

        menuElement.addEventListener('click', () => {
            this.select(index);
        });

        return this;
    }

    ready(): Menu {
        this.select(0);
        return this;
    }

    select(index: number) {
        if (index < 0 || index >= this._entries.length) {
            return;
        }

        this._collapseAll();

        const entry = this._entries[index];

        entry.menuElement.classList.add('selected');
        entry.onShow?.();
        UiUtils.show(entry.contentElement);
    }

    _collapseAll() {
        for (const { menuElement, contentElement, onHide } of this._entries) {
            if (menuElement.classList.contains('selected')) {
                onHide?.();
                menuElement.classList.remove('selected');
            }
            UiUtils.collapse(contentElement);
        }
    }
}

export class Subscription {
    private _unsubscribeFunctions: Function[] | undefined;

    addUnsubscribeFunction(unsubscribeFunction: Function): void {
        if (typeof unsubscribeFunction !== 'function') {
            throw new TypeError(`Argument 'unsubscribeFunction' must be of type 'function', received '${unsubscribeFunction}' of type '${typeof unsubscribeFunction}'`);
        }

        if (!this._unsubscribeFunctions) {
            this._unsubscribeFunctions = [];
        }

        this._unsubscribeFunctions.push(unsubscribeFunction);
    }

    unsubscribe(): void {
        if (!this._unsubscribeFunctions) {
            return;
        }

        for (const f of this._unsubscribeFunctions) {
            try {
                f();
            } catch (error) {
                console.error(error);
            }
        }

        this._unsubscribeFunctions = undefined;
    }
}

interface EventSubscriptionInfo {
    eventSubscription?: Subscription
    type: string
    listener: EventListenerOrEventListenerObject
    options?: AddEventListenerOptions
}

export type RunFunction = (element: HTMLElement, builder: HtmlElementBuilder) => void;

export class HtmlElementBuilder {
    private _element: HTMLElement;
    private _typeOfInputElement?: string;
    private _classes?: string[];
    private _children?: (HTMLElement | HtmlElementBuilder)[];
    private _attributes?: {[key: string]: any};
    private _properties?: {[key: string]: any};
    private _styles?: {[key: string]: any};
    private _eventListeners?: EventSubscriptionInfo[];
    private _functions?: RunFunction[];

    constructor(tagNameOrElement: HTMLElement | string, typeOfInputElement?: string) {
        let element: HTMLElement;

        if (typeof tagNameOrElement === 'string') {
            HtmlElementBuilder.checkParametersWithTagName(tagNameOrElement, typeOfInputElement);
            element = document.createElement(tagNameOrElement);
        } else if (tagNameOrElement instanceof HTMLElement) {
            HtmlElementBuilder.checkParametersWithHtmlElement(tagNameOrElement, typeOfInputElement);
            element = tagNameOrElement;
        } else {
            throw new TypeError(`Unknown argument 'tagNameOrElement', value '${tagNameOrElement}', type '${typeof tagNameOrElement}'.`);
        }

        this._typeOfInputElement = typeOfInputElement;
        this._element = element;
    }

    static checkParametersWithTagName(tagName: string, typeOfInputElement?: string): void {
        if (tagName === 'input' && !typeOfInputElement) {
            throw new TypeError('Element \'input\' requires a \'type\'.');
        } else if (tagName !== 'input' && typeOfInputElement) {
            throw new TypeError('Element not \'input\' must not have a \'type\'.');
        }
    }

    static checkParametersWithHtmlElement(element: HTMLElement, typeOfInputElement?: string): void {
        if (element instanceof HTMLInputElement && !typeOfInputElement) {
            throw new TypeError('Element of type HTMLInputElement requires a \'type\'.');
        } else if ((element instanceof HTMLInputElement) === false && typeOfInputElement) {
            throw new TypeError('Element not of type HTMLInputElement must not have a \'type\'.');
        }
    }

    addClasses(...classes: string[]): HtmlElementBuilder {
        if (!this._classes) {
            this._classes = [];
        }

        this._classes.push(...classes);

        return this;
    }

    addChildren(...children: (HTMLElement | HtmlElementBuilder)[]): HtmlElementBuilder {
        return this._addChildren(false, ...children);
    }

    addChildrenAllowUnset(...children: (HTMLElement | HtmlElementBuilder | null)[]): HtmlElementBuilder {
        return this._addChildren(true, ...children);
    }

    _addChildren(allowUnset: boolean, ...children: (HTMLElement | HtmlElementBuilder | null)[]): HtmlElementBuilder {
        if (!this._children) {
            this._children = [];
        }

        let elements: (HTMLElement | HtmlElementBuilder | null)[];

        if (arguments.length === 1 && Array.isArray(children[0])) {
            elements = children[0];
        } else {
            elements = children;
        }

        let index = -1;

        for (const element of elements) {
            index++;

            if (!element) {
                if (allowUnset) {
                    continue;
                }
                throw new Error(`Element at index ${index} is unset, but must be.`);
            }

            this._children.push(element);
        }

        return this;
    }

    setAttributes(object: {[key: string]: any}): HtmlElementBuilder {
        if (!this._attributes) {
            this._attributes = {};
        }

        for (const [key, value] of Object.entries(object)) {
            // Last value takes precedence.
            this._attributes[key] = value;
        }

        return this;
    }

    setProperties(object: {[key: string]: any}): HtmlElementBuilder {
        if (!this._properties) {
            this._properties = {};
        }

        for (const [key, value] of Object.entries(object)) {
            // Last value takes precedence.
            this._properties[key] = value;
        }

        return this;
    }

    setStyles(object: {[key: string]: any}): HtmlElementBuilder {
        if (!this._styles) {
            this._styles = {};
        }

        for (const [key, value] of Object.entries(object)) {
            // Last value takes precedence.
            this._styles[key] = value;
        }

        return this;
    }

    addEventListener(eventSubscription: Subscription, type: string, listener: EventListenerOrEventListenerObject, options?: AddEventListenerOptions): HtmlElementBuilder {
        if (!this._eventListeners) {
            this._eventListeners = [];
        }

        this._eventListeners.push({
            eventSubscription,
            type,
            listener,
            options
        });

        return this;
    }

    run(...functions: RunFunction[]): HtmlElementBuilder {
        if (!this._functions) {
            this._functions = [];
        }

        this._functions.push(...functions);

        return this;
    }

    build(): HTMLElement {
        if (this._classes) {
            for (const cls of this._classes) {
                this._element.classList.add(cls);
            }
        }

        if (this._children) {
            for (let child of this._children) {
                if (!child) {
                    continue;
                }

                if (child instanceof HtmlElementBuilder) {
                    child = child.build();
                }

                this._element.appendChild(child);
            }
        }

        if (this._attributes) {
            for (const [key, value] of Object.entries(this._attributes)) {
                this._element.setAttribute(key, value);
            }
        }

        if (this._properties) {
            for (const [propertyName, value] of Object.entries(this._properties)) {
                (this._element as any)[propertyName] = value;
            }
        }

        if (this._typeOfInputElement) {
            (this._element as any).type = this._typeOfInputElement;
        }

        if (this._styles) {
            for (const [key, value] of Object.entries(this._styles)) {
                (this._element.style as any)[key] = value;
            }
        }

        if (this._eventListeners) {
            for (const { eventSubscription, type, listener, options } of this._eventListeners) {
                this._element.addEventListener(type, listener, options);
                if (eventSubscription) {
                    eventSubscription.addUnsubscribeFunction(() => {
                        this._element.removeEventListener(type, listener, options);
                    });
                }
            }
        }

        if (this._functions) {
            for (const f of this._functions) {
                f(this._element, this);
            }
        }

        return this._element;
    }
}

export class UiUtils {
    static addEventListener(eventSubscription: Subscription, eventSource: DocumentAndElementEventHandlers, type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
        eventSource.addEventListener(type, listener, options);
        eventSubscription.addUnsubscribeFunction(() => {
            eventSource.removeEventListener(type, listener, options);
        });
    }

    static addEnterKeyEventListener(eventSubscription: Subscription, eventSource: DocumentAndElementEventHandlers, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
        UiUtils.addEventListener(eventSubscription, eventSource, 'keydown', (evt) => {
            if (evt.defaultPrevented) {
                return;
            }

            const keyEvent: KeyboardEvent = <KeyboardEvent>evt;

            if (keyEvent.key === 'Enter') {
                const anyListener: any = listener;

                if (anyListener.handleEvent) {
                    anyListener.handleEvent(evt);
                } else {
                    anyListener(evt);
                }

                evt.preventDefault();
            }
        }, options);
    }

    static getElementByClassName<T extends HTMLElement>(className: string): T {
        const elements = document.getElementsByClassName(className);
        if (elements.length === 0) {
            throw new Error(`Could not find element with class '${className}'.`);
        }
        return <T>elements[0];
    }

    static querySelectElement<T extends HTMLElement>(selectors: string): T {
        const element = document.querySelector(selectors);

        if (element === null) {
            throw new Error(`Could not find element with selectors '${selectors}'.`);
        }

        return <T>element;
    }

    static attachLabelAndInput(label: HTMLElement, input: HTMLElement): void {
        const id = `label_${RandomUtils.largeNumberString(16)}`;
        label.setAttribute('for', id);
        input.id = id;
    }

    static createTable(headerElements: HTMLElement[] | null, rows: HTMLElement[][]): HTMLElement {
        const tableElementBuilder = new HtmlElementBuilder('table');

        if (headerElements) {
            const headerElementBuilder = new HtmlElementBuilder('th');

            for (const headerElement of headerElements) {
                const cellElement = new HtmlElementBuilder('td')
                    .addChildren(headerElement)
                    .build();
                headerElementBuilder.addChildren(cellElement);
            }

            tableElementBuilder.addChildren(headerElementBuilder.build());
        }

        for (const row of rows) {
            const rowElementBuilder = new HtmlElementBuilder('tr');

            for (const cellUserElement of row) {
                const cellElement = new HtmlElementBuilder('td')
                    .addChildren(cellUserElement)
                    .build();
                cellUserElement.classList.add('value-element');
                rowElementBuilder.addChildren(cellElement);
            }

            tableElementBuilder.addChildren(rowElementBuilder.build());
        }

        return tableElementBuilder.build();
    }

    static createTextElement(text: string, classes: string[] = []): HTMLElement {
        return new HtmlElementBuilder('span')
            .setProperties({ innerText: text })
            .addClasses(...classes)
            .build();
    }

    static wrapInGrid(element: HTMLElement): HTMLElement {
        return new HtmlElementBuilder('div')
            .setStyles({ display: 'grid' })
            .addChildren(element)
            .build();
    }

    static wrapInTableCell(element: HTMLElement): HTMLElement {
        return new HtmlElementBuilder('td')
            .addChildren(element)
            .build();
    }

    static wrapInNewParent(parentElementTagName: HTMLElement | string, existingElement: HTMLElement): HTMLElement {
        return new HtmlElementBuilder(parentElementTagName)
            .addChildren(existingElement)
            .build();
    }

    static show(element: HTMLElement): void {
        if (element && element.classList) {
            element.classList.remove(VISIBILITY_HIDDEN_CLASS_NAME);
            element.classList.remove(VISIBILITY_COLLAPSE_CLASS_NAME);
        }
    }

    static hide(element: HTMLElement): void {
        // A call to show to ensure it's not collapsed.
        UiUtils.show(element);

        if (element && element.classList) {
            element.classList.add(VISIBILITY_HIDDEN_CLASS_NAME);
        }
    }

    static collapse(element: HTMLElement): void {
        if (element && element.classList) {
            element.classList.add(VISIBILITY_COLLAPSE_CLASS_NAME);
        }
    }

    static createImageElementFromUrl(url: string, altText: string): Promise<HTMLImageElement> {
        return new Promise((resolve) => {
            new HtmlElementBuilder('img')
                .setProperties({
                    src: url,
                    alt: altText
                })
                .run(x => { x.onload = () => resolve(<HTMLImageElement>x); })
                .build();
        });
    }

    static async createImageElementFromFile(file: File, altText: string): Promise<HTMLImageElement> {
        return await UiUtils.createImageElementFromUrl(window.URL.createObjectURL(file), altText);
    }

    static disposeImageElement(imageElement: HTMLImageElement): void {
        window.URL.revokeObjectURL(imageElement.src);
    }

    static disable(sourceElement: HTMLElement): void {
        (sourceElement as any).disabled = true;
    }

    static enable(sourceElement: HTMLElement): void {
        (sourceElement as any).disabled = false;
    }

    static beginDisable(sourceElement: HTMLElement): Function {
        const originalDisabled = (sourceElement as any).disabled;
        (sourceElement as any).disabled = true;
        return () => {
            (sourceElement as any).disabled = originalDisabled;
        };
    }

    static beginBorderAnimation(sourceElement: HTMLElement): Function {
        sourceElement.classList.add('animated-border');
        return () => sourceElement.classList.remove('animated-border');
    }

    static createThumbnail(image: HTMLImageElement, title: string, thumbnailHeight: number): HTMLCanvasElement {
        const ratio = image.width / image.height;

        const canvas = <HTMLCanvasElement>new HtmlElementBuilder('canvas')
            .setProperties({
                width: thumbnailHeight * ratio,
                height: thumbnailHeight,
                title
            })
            .build();

        canvas.getContext('2d')?.drawImage(image, 0, 0, canvas.width, canvas.height);

        return canvas;
    }

    static imageLoadAsync(imageElement: HTMLImageElement): Promise<void> {
        return new Promise(resolve => {
            const listener = () => {
                imageElement.removeEventListener('load', listener);
                resolve();
            };
            imageElement.addEventListener('load', listener);
        });
    }

    static makeUnit(value: string | number, unit: string): string {
        if (typeof value === 'number') {
            return value === 0 ? String(value) : `${value}${unit}`;
        }

        if (typeof value === 'string') {
            return value === '0' ? value : `${value}${unit}`;
        }

        throw new TypeError(`Invalid value '${value}' of type '${typeof value}'.`);
    }
}
